import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { API_BASE_URL } from '../../config/api';

interface ICardSpecs {
    [key: string]: string;
}

export interface IProduct {
    id: string;
    name: string;
    image: string;
    description: string;
    categoryId: string;
    card_specs: ICardSpecs;
}

export type IContentBlock =
    ITextBlock
    | ITableBlock
    | IListBlock
    | IImageGalleryBlock
    | IFileBlock
    | ITitle
    | ICombinedBlock
    | IDivider;

export type ICombinedContentBlock =
    ITextBlock
    | ITableBlock
    | IListBlock
    | IImageGalleryBlock
    | IFileBlock
    | ITitle
    | IDivider;

export interface ICombinedBlock {
    type: 'combined';
    content: ICombinedContentBlock[];
}

export interface IDivider {
    type: 'divider';
}

export interface ITextBlock {
    type: 'text';
    title?: string;
    content: string;
}

export interface ITitle {
    type: 'title';
    text: string;
    level: number;
}

export interface ITableBlock {
    type: 'table';
    title?: string;
    headers: (string | ITableCellData)[];
    rows: Array<(string | ITableCellData)[]>;
}

export interface ITableCellData {
    content: string;
    colspan?: number;
    rowspan?: number;
    align?: 'left' | 'center' | 'right';
    verticalAlign?: 'top' | 'middle' | 'bottom';
}

export interface IListBlock {
    type: 'list';
    title?: string;
    items: string[];
}

export interface IImageGalleryBlock {
    type: 'imageGallery';
    images: string[];
    imageFiles: (File | null)[];
}

export interface IFileBlockFile {
    name: string;
    url: string;
    file?: File | null;
}

export interface IFileBlock {
    type: 'file';
    title?: string;
    files: IFileBlockFile[];
}

export interface IProductDetails {
    id: string;
    name: string;
    image: string;
    description: string;
    categoryId: string;
    card_specs: { [key: string]: string };
    mainImages: string[];
    contentBlocks: IContentBlock[];
}

interface IProductState {
    byCategory: {
        [categoryId: string]: {
            products: IProduct[];
            loading: boolean;
            error: string | null;
        };
    };
    productDetails: {
        [productId: string]: {
            details: IProductDetails | null;
            loading: boolean;
            error: string | null;
        };
    };
    productInfo: {
        [productId: string]: {
            product: IProduct | null;
            loading: boolean;
            error: string | null;
        };
    };
}

const initialState: IProductState = {
    byCategory: {},
    productDetails: {},
    productInfo: {},
};

export const fetchProductInfo = createAsyncThunk(
    'products/fetchProductInfo',
    async (productId: string) => {
        const response = await fetch(`/api/products/${productId}`);
        if (response.status === 404) {
            throw new Error('Продукт не найден');
        }
        if (!response.ok) {
            throw new Error('Ошибка при загрузке информации о продукте');
        }
        const data = await response.json();
        return {productId, product: data as IProduct};
    }
);

export const fetchProductsByCategory = createAsyncThunk(
    'products/fetchByCategory',
    async (categoryId: string) => {
        const response = await fetch(`/api/categories/${categoryId}/products`);
        if (response.status === 404) {
            return {categoryId, products: []};
        }
        if (!response.ok) {
            throw new Error('Ошибка при загрузке товаров');
        }
        const data = await response.json();
        return {categoryId, products: data as IProduct[]};
    }
);

export const fetchProductDetails = createAsyncThunk(
    'products/fetchProductDetails',
    async (productId: string) => {
        const response = await fetch(`/api/products/${productId}/details`);
        if (response.status === 404) {
            throw new Error('Продукт не найден');
        }
        if (!response.ok) {
            throw new Error('Ошибка при загрузке деталей продукта');
        }
        const data = await response.json();
        return {productId, details: data as IProductDetails};
    }
);

export interface ProductFormData {
    name: string;
    image: string;
    description: string;
    categoryId: string;
    card_specs: { [key: string]: string };
    mainImages: string[];
    contentBlocks: IContentBlock[];
    imageFile: File | null;
    mainImageFiles: (File | null)[];
}

export const createProduct = createAsyncThunk(
    'products/createProduct',
    async (data: ProductFormData) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('categoryId', data.categoryId);
        formData.append('card_specs', JSON.stringify(data.card_specs));

        // Основное изображение
        if (data.imageFile) {
            formData.append('image', data.imageFile);
        }

        // Оновные изображения галереи
        data.mainImageFiles.forEach((file, index) => {
            if (file) {
                formData.append(`mainImage_${index}`, file);
            }
        });

        // Обработка контентных блоков и их изображений
        const processedBlocks = data.contentBlocks.map((block, blockIndex) => {
            if (block.type === 'imageGallery') {
                const imageGalleryBlock = block as IImageGalleryBlock;
                // Считаем количество imageGallery блоков до текущего
                const galleryIndex = data.contentBlocks
                    .slice(0, blockIndex)
                    .filter(b => b.type === 'imageGallery')
                    .length;

                // Добавляем проверку на существование imageFiles
                if (imageGalleryBlock.imageFiles) {
                    imageGalleryBlock.imageFiles.forEach((file, imageIndex) => {
                        if (file) {
                            formData.append(
                                `block_${galleryIndex}_image_${imageIndex}`,
                                file
                            );
                        }
                    });
                }
                const {imageFiles, ...cleanBlock} = imageGalleryBlock;
                return cleanBlock;
            }
            if (block.type === 'file') {
                const fileBlock = block as IFileBlock;
                // Считаем количество file блоков до текущего
                const fileIndex = data.contentBlocks
                    .slice(0, blockIndex)
                    .filter(b => b.type === 'file')
                    .length;

                fileBlock.files.forEach((fileItem, itemIndex) => {
                    if (fileItem.file) {
                        formData.append(
                            `block_${fileIndex}_file_${itemIndex}`,
                            fileItem.file
                        );
                    }
                });
                
                // Очищаем объект от File перед отправкой
                const cleanFiles = fileBlock.files.map(f => ({
                    name: f.name,
                    url: f.url
                }));
                return { ...fileBlock, files: cleanFiles };
            }
            return block;
        });

        formData.append('contentBlocks', JSON.stringify(processedBlocks));

        const response = await fetch(`/api/products/`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Ошибка при создании продукта');
        }

        const responseData = await response.json();
        return responseData;
    }
);

export const updateProduct = createAsyncThunk(
    'products/updateProduct',
    async ({productId, data}: { productId: string; data: ProductFormData }) => {
        console.log(data)
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('categoryId', data.categoryId);
        formData.append('card_specs', JSON.stringify(data.card_specs));

        if (data.imageFile) {
            formData.append('image', data.imageFile);
        }

        data.mainImageFiles?.forEach((file, index) => {
            if (file) {
                formData.append(`mainImage_${index}`, file);
            }
        });

        const processedBlocks = data.contentBlocks.map((block, blockIndex) => {
            if (block.type === 'imageGallery') {
                const imageGalleryBlock = block as IImageGalleryBlock;
                // Считаем количество imageGallery блоков до текущего
                const galleryIndex = data.contentBlocks
                    .slice(0, blockIndex)
                    .filter(b => b.type === 'imageGallery')
                    .length;

                // Добавляем проверку на существование imageFiles
                if (imageGalleryBlock.imageFiles) {
                    imageGalleryBlock.imageFiles.forEach((file, imageIndex) => {
                        if (file) {
                            formData.append(
                                `block_${galleryIndex}_image_${imageIndex}`,
                                file
                            );
                        }
                    });
                }
                const {imageFiles, ...cleanBlock} = imageGalleryBlock;
                return cleanBlock;
            }
            if (block.type === 'file') {
                const fileBlock = block as IFileBlock;
                const fileIndex = data.contentBlocks
                    .slice(0, blockIndex)
                    .filter(b => b.type === 'file')
                    .length;

                fileBlock.files.forEach((fileItem, itemIndex) => {
                    if (fileItem.file) {
                        formData.append(
                            `block_${fileIndex}_file_${itemIndex}`,
                            fileItem.file
                        );
                    }
                });
                
                const cleanFiles = fileBlock.files.map(f => ({
                    name: f.name,
                    url: f.url
                }));
                return { ...fileBlock, files: cleanFiles };
            }
            return block;
        });

        formData.append('contentBlocks', JSON.stringify(processedBlocks));
        formData.append('mainImages', JSON.stringify(data.mainImages));

        const response = await fetch(`/api/products/${productId}/`, {
            method: 'PUT',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Ошибка при обновлении продукта');
        }

        const responseData = await response.json();
        return responseData;
    }
);

export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async (productId: string) => {
        const response = await fetch(`/api/products/${productId}`, {
            method: 'DELETE',
        });
        
        if (!response.ok) {
            throw new Error('Ошибка при удалении продукта');
        }
        
        return productId;
    }
);

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductsByCategory.pending, (state, action) => {
                const categoryId = action.meta.arg;
                if (!state.byCategory[categoryId]) {
                    state.byCategory[categoryId] = {
                        products: [],
                        loading: true,
                        error: null,
                    };
                } else {
                    state.byCategory[categoryId].loading = true;
                    state.byCategory[categoryId].error = null;
                }
            })
            .addCase(fetchProductsByCategory.fulfilled, (state, action) => {
                const {categoryId, products} = action.payload;
                state.byCategory[categoryId] = {
                    products,
                    loading: false,
                    error: null,
                };
            })
            .addCase(fetchProductsByCategory.rejected, (state, action) => {
                const categoryId = action.meta.arg;
                if (!state.byCategory[categoryId]) {
                    state.byCategory[categoryId] = {
                        products: [],
                        loading: false,
                        error: action.error.message || 'Ошибка',
                    };
                } else {
                    state.byCategory[categoryId].loading = false;
                    state.byCategory[categoryId].error = action.error.message || 'Ошибка';
                }
            })
            .addCase(fetchProductDetails.pending, (state, action) => {
                const productId = action.meta.arg;
                if (!state.productDetails[productId]) {
                    state.productDetails[productId] = {
                        details: null,
                        loading: true,
                        error: null,
                    };
                } else {
                    state.productDetails[productId].loading = true;
                    state.productDetails[productId].error = null;
                }
            })
            .addCase(fetchProductDetails.fulfilled, (state, action) => {
                const {productId, details} = action.payload;
                state.productDetails[productId] = {
                    details,
                    loading: false,
                    error: null,
                };
            })
            .addCase(fetchProductDetails.rejected, (state, action) => {
                const productId = action.meta.arg;
                if (!state.productDetails[productId]) {
                    state.productDetails[productId] = {
                        details: null,
                        loading: false,
                        error: action.error.message || 'Ошибка',
                    };
                } else {
                    state.productDetails[productId].loading = false;
                    state.productDetails[productId].error = action.error.message || 'Ошибка';
                }
            })
            .addCase(fetchProductInfo.pending, (state, action) => {
                const productId = action.meta.arg;
                if (!state.productInfo[productId]) {
                    state.productInfo[productId] = {
                        product: null,
                        loading: true,
                        error: null,
                    };
                } else {
                    state.productInfo[productId].loading = true;
                    state.productInfo[productId].error = null;
                }
            })
            .addCase(fetchProductInfo.fulfilled, (state, action) => {
                const {productId, product} = action.payload;
                state.productInfo[productId] = {
                    product,
                    loading: false,
                    error: null,
                };
            })
            .addCase(fetchProductInfo.rejected, (state, action) => {
                const productId = action.meta.arg;
                if (!state.productInfo[productId]) {
                    state.productInfo[productId] = {
                        product: null,
                        loading: false,
                        error: action.error.message || 'Ошибка при загрузке информации о продукте',
                    };
                } else {
                    state.productInfo[productId].loading = false;
                    state.productInfo[productId].error = action.error.message || 'Ошибка при загрузке информации о продукте';
                }
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                const productId = action.payload;
                delete state.productDetails[productId];
                delete state.productInfo[productId];
            });
    },
});

export default productSlice.reducer; 