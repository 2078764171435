import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, CircularProgress, Typography } from '@mui/material';
import AddProductPage from './AddProductPage';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchProductDetails, fetchProductInfo } from '../store/slices/productSlice';

const EditProductPage: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const dispatch = useAppDispatch();
  
  const productDetails = useAppSelector(
    (state) => state.products.productDetails[productId!]?.details
  );
  const productInfo = useAppSelector(
    (state) => state.products.productInfo[productId!]?.product
  );
  const loading = useAppSelector(
    (state) => 
      state.products.productDetails[productId!]?.loading || 
      state.products.productInfo[productId!]?.loading
  );
  const error = useAppSelector(
    (state) => 
      state.products.productDetails[productId!]?.error || 
      state.products.productInfo[productId!]?.error
  );

  useEffect(() => {
    if (productId) {
      dispatch(fetchProductDetails(productId));
      dispatch(fetchProductInfo(productId));
    }
  }, [dispatch, productId]);

  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h6" color="error">
          Ошибка: {error}
        </Typography>
      </Container>
    );
  }

  if (!productDetails || !productInfo) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h6">
          Продукт не найден
        </Typography>
      </Container>
    );
  }

  return (
    <AddProductPage 
      isEditing={true}
      productId={productId}
      initialData={{
        name: productInfo.name,
        image: productInfo.image,
        description: productInfo.description,
        categoryId: productInfo.categoryId,
        card_specs: productInfo.card_specs,
        mainImages: productDetails.mainImages,
        contentBlocks: productDetails.contentBlocks,
        imageFile: null,
        mainImageFiles: [],
      }}
    />
  );
};

export default EditProductPage; 