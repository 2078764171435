import React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ICombinedBlock, ICombinedContentBlock } from '../../../store/slices/productSlice';
import ContentBlockEditor from '../ContentBlockEditor';
import AddBlockMenu from '../AddBlockMenu';

interface CombinedBlockEditorProps {
  block: ICombinedBlock;
  onUpdate: (block: ICombinedBlock) => void;
}

const CombinedBlockEditor: React.FC<CombinedBlockEditorProps> = ({ block, onUpdate }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMoveBlock = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= block.content.length) return;

    const newContent = [...block.content];
    const [movedBlock] = newContent.splice(fromIndex, 1);
    newContent.splice(toIndex, 0, movedBlock);
    
    onUpdate({
      ...block,
      content: newContent
    });
  };

  const handleAddBlock = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBlockTypeSelect = (type: string) => {
    if (type === 'combined') return;

    let newBlock: ICombinedContentBlock;

    switch (type) {
      case 'text':
        newBlock = { type: 'text', content: '', title: '' };
        break;
      case 'title':
        newBlock = { type: 'title', text: '', level: 2 };
        break;
      case 'table':
        newBlock = { type: 'table', headers: [], rows: [], title: '' };
        break;
      case 'list':
        newBlock = { type: 'list', items: [], title: '' };
        break;
      case 'imageGallery':
        newBlock = { type: 'imageGallery', images: [], imageFiles: [] };
        break;
      case 'divider':
        newBlock = { type: 'divider' };
        break;
      default:
        return;
    }

    onUpdate({
      ...block,
      content: [...block.content, newBlock]
    });
    setAnchorEl(null);
  };

  return (
    <Box>
      {block.content.map((contentBlock, index) => (
        <Box key={index} sx={{ mb: 2, position: 'relative' }}>
          <Box sx={{ position: 'absolute', right: -40, top: 10, display: 'flex', flexDirection: 'column' }}>
            <IconButton 
              size="small"
              onClick={() => handleMoveBlock(index, index - 1)}
              disabled={index === 0}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton 
              size="small"
              onClick={() => handleMoveBlock(index, index + 1)}
              disabled={index === block.content.length - 1}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Box>
          <ContentBlockEditor
            block={contentBlock}
            isCombined={true}
            onUpdate={(updatedBlock) => {
              const newContent = [...block.content];
              newContent[index] = updatedBlock as ICombinedContentBlock;
              onUpdate({ ...block, content: newContent });
            }}
            onDelete={() => {
              onUpdate({
                ...block,
                content: block.content.filter((_, i) => i !== index)
              });
            }}
          />
        </Box>
      ))}

      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddBlock}
        sx={{ mt: 2 }}
      >
        Добавить блок
      </Button>

      <AddBlockMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onSelect={handleBlockTypeSelect}
        hideCombined={true}
      />
    </Box>
  );
};

export default CombinedBlockEditor; 