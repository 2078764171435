import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TableChartIcon from '@mui/icons-material/TableChart';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CollectionsIcon from '@mui/icons-material/Collections';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TitleIcon from '@mui/icons-material/Title';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

interface AddBlockMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onSelect: (type: string) => void;
  hideCombined?: boolean;
}

const AddBlockMenu: React.FC<AddBlockMenuProps> = ({ anchorEl, onClose, onSelect, hideCombined = false }) => {
  const handleSelect = (type: string) => {
    onSelect(type);
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem onClick={() => handleSelect('text')}>
        <ListItemIcon>
          <TextFieldsIcon />
        </ListItemIcon>
        <ListItemText>Текстовый блок</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSelect('title')}>
        <ListItemIcon>
          <TitleIcon />
        </ListItemIcon>
        <ListItemText>Заголовок</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSelect('table')}>
        <ListItemIcon>
          <TableChartIcon />
        </ListItemIcon>
        <ListItemText>Таблица</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSelect('list')}>
        <ListItemIcon>
          <FormatListBulletedIcon />
        </ListItemIcon>
        <ListItemText>Список</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSelect('imageGallery')}>
        <ListItemIcon>
          <CollectionsIcon />
        </ListItemIcon>
        <ListItemText>Галерея изображений</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSelect('file')}>
        <ListItemIcon>
          <AttachFileIcon />
        </ListItemIcon>
        <ListItemText>Файлы</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSelect('divider')}>
        <ListItemIcon>
          <ViewDayIcon />
        </ListItemIcon>
        <ListItemText>Разделитель</ListItemText>
      </MenuItem>
      {!hideCombined && (
        <MenuItem onClick={() => handleSelect('combined')}>
          <ListItemIcon>
            <DashboardCustomizeIcon />
          </ListItemIcon>
          <ListItemText primary="Комбинированный блок" />
        </MenuItem>
      )}
    </Menu>
  );
};

export default AddBlockMenu; 