import React, { useRef, useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Box,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Category } from '../store/slices/catalogSlice';
import { ParallaxBanner } from 'react-scroll-parallax';
import HoverLink from './HoverLink';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { IProduct } from '../store/slices/productSlice';

interface CategoryCardProps {
    category: Category;
    products?: IProduct[];
    height?: number | string;
    maxHeight?: number;
    onEditClick?: (category: Category) => void;
    onDeleteClick?: (category: Category) => void;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category, products, height = 270, maxHeight = 270, onEditClick, onDeleteClick }) => {
    const cardContentRef = useRef<HTMLDivElement | null>(null);
    const [visibleItems, setVisibleItems] = useState<Category[] | IProduct[]>([]);
    const [remainingCount, setRemainingCount] = useState(0);
    const [isReady, setIsReady] = useState(false);

    const items = !category.children || category.children.length === 0 ? products : category.children;

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsReady(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const updateVisibleItems = () => {
        if (!cardContentRef.current || !items || !isReady) return;

        const cardHeight = cardContentRef.current.clientHeight;
        const titleHeight = cardContentRef.current.querySelector('.category-item')?.getBoundingClientRect().height || 0;
        const buttonHeight = 40;
        const availableHeight = cardHeight - titleHeight - buttonHeight;

        setVisibleItems(items);

        setTimeout(() => {
            const itemElements = cardContentRef.current?.querySelectorAll('.subcategory-item, .product-item') || [];
            let totalHeight = 0;
            let visibleCount = 0;

            itemElements.forEach((item, index) => {
                const itemHeight = item.getBoundingClientRect().height;
                if (totalHeight + itemHeight + 8 + 28 <= availableHeight) {
                    totalHeight += itemHeight + 8;
                    visibleCount = index + 1;
                }
            });

            setVisibleItems(items.slice(0, visibleCount));
            setRemainingCount(Math.max(0, items.length - visibleCount));
        }, 1);
    };

    useEffect(() => {
        if (isReady) {
            updateVisibleItems();

            const handleResize = () => {
                requestAnimationFrame(updateVisibleItems);
            };

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [isReady, items]);

    const canDelete = !category.children?.length && (!products || products.length === 0);

    return (
        <Card
            sx={{
                height: height,
                width: '100%',
                maxHeight: maxHeight,
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid black',
                boxShadow: 3,
                position: 'relative',
                overflow: 'hidden',
                transition: 'box-shadow 0.3s ease',
                borderRadius: '16px',
                '&:hover': {
                    boxShadow: 6,
                },
                backgroundColor: '#fff9f9',
            }}
        >
            <ParallaxBanner
                layers={[
                    {
                        image: category.image ? category.image : "/images/placeholder.jpg",
                        speed: -10,
                    },
                ]}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    zIndex: 0,
                }}
            />

            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    zIndex: 1,
                }}
            />

            <CardContent
                ref={cardContentRef}
                sx={{
                    flexGrow: 1,
                    zIndex: 2,
                    maxHeight: 'inherit',
                    position: 'relative',
                    color: '#fff',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        zIndex: 3,
                        display: 'flex',
                        gap: 1
                    }}
                >
                    {onEditClick && (
                        <IconButton
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditClick(category);
                            }}
                            sx={{ 
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                '&:hover': { 
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)' 
                                } 
                            }}
                            size="small"
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    )}
                    {onDeleteClick && (
                        <Tooltip title={!canDelete ? "Нельзя удалить категорию с подкатегориями или товарами" : ""}>
                            <span>
                                <IconButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (canDelete) {
                                            onDeleteClick(category);
                                        }
                                    }}
                                    sx={{ 
                                        color: 'white',
                                        backgroundColor: 'rgba(255, 0, 0, 0.3)',
                                        '&:hover': { 
                                            backgroundColor: canDelete ? 'rgba(255, 0, 0, 0.5)' : 'rgba(255, 0, 0, 0.3)'
                                        },
                                        '&.Mui-disabled': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            color: 'rgba(255, 255, 255, 0.3)'
                                        }
                                    }}
                                    size="small"
                                    disabled={!canDelete}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                </Box>

                <HoverLink
                    to={`/catalog/${category.id}`}
                    label={category.name}
                    color="inherit"
                    variant="h4"
                    underlineColor="white"
                    className="category-item"
                    sx={{
                        fontWeight: '600',
                        fontSize: '36px',
                    }}
                />

                {visibleItems && visibleItems.length > 0 && (
                    <List dense sx={{ mt: 1 }}>
                        {visibleItems.map((item) => (
                            <ListItem
                                key={item.id}
                                disablePadding
                                className={category.children ? 'subcategory-item' : 'product-item'}
                            >
                                <ListItemText
                                    primary={
                                        <HoverLink
                                            to={
                                                category.children
                                                    ? `/catalog/${(item as Category).id}`
                                                    : `/products/${(item as IProduct).id}`
                                            }
                                            label={category.children ? (item as Category).name : (item as IProduct).name}
                                            color="inherit"
                                            variant="h4"
                                            underlineColor="white"
                                            sx={{
                                                fontWeight: '400',
                                                fontSize: '20px',
                                                mb: 1,
                                            }}
                                        />
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                )}

                {remainingCount > 0 && (
                    <Box>
                        <Button
                            size="small"
                            component={Link}
                            to={`/catalog/${category.id}`}
                            sx={{
                                textDecoration: 'none',
                                padding: 0,
                                color: 'inherit',
                                transition: 'text-decoration 0.3s ease',
                                fontWeight: '400',
                                fontSize: '16px',
                                textTransform: 'inherit',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            Ещё +{remainingCount}
                        </Button>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default CategoryCard;
