import React, { useRef } from 'react';
import { Container, Card, CardContent } from '@mui/material';
import {IProductDetails, ITitle} from '../../store/slices/productSlice';
import ContentBlock from '../DataPresentation/ContentBlock';
import Title from '../DataPresentation/Title';
import Images from '../DataPresentation/Images';
import ProductPreviewHeader from './ProductPreviewHeader';
import AnimatedCard from "../AnimatedCard";

interface ProductPreviewPageProps {
  formData: IProductDetails;
}

const ProductPreviewPage: React.FC<ProductPreviewPageProps> = ({ formData }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <ProductPreviewHeader productDetails={formData} scrollToContent={scrollToContent} />
      <Container sx={{ mt: 4 }} ref={contentRef}>
        <Title name={formData.name} />
        <Images images={formData.mainImages} />

        {formData.contentBlocks.map((block, index) => {
            if (block.type === 'title') {
                return (
                    <AnimatedCard key={index}>
                        <Title
                            name={(block as ITitle).text}
                            level={(block as ITitle).level}
                        />
                    </AnimatedCard>
                );
            } else {
                return (
                    <AnimatedCard key={index}>
                        <Card sx={{ mb: 2, borderRadius: 2, boxShadow: 3, padding: 2 }}>
                            <CardContent>
                                <ContentBlock block={block} />
                            </CardContent>
                        </Card>
                    </AnimatedCard>
                );
            }
        })}
      </Container>
    </>
  );
};

export default ProductPreviewPage;