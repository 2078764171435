import React from 'react';
import { Box, Typography, IconButton, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IContentBlock, ICombinedBlock } from '../../store/slices/productSlice';
import CombinedBlockEditor from './BlockEditors/CombinedBlockEditor';
import TextBlockEditor from './BlockEditors/TextBlockEditor';
import TitleBlockEditor from './BlockEditors/TitleBlockEditor';
import TableBlockEditor from './BlockEditors/TableBlockEditor';
import ListBlockEditor from './BlockEditors/ListBlockEditor';
import FileBlockEditor from './BlockEditors/FileBlockEditor';
import ImageGalleryBlockEditor from './BlockEditors/ImageGalleryBlockEditor';
import ContentBlock from '../DataPresentation/ContentBlock';

interface ContentBlockEditorProps {
  block: IContentBlock;
  onUpdate: (block: IContentBlock) => void;
  onDelete: () => void;
  isCombined?: boolean;
}

const ContentBlockEditor: React.FC<ContentBlockEditorProps> = ({
  block,
  onUpdate,
  onDelete,
  isCombined = false,
}) => {
  const renderEditor = () => {
    switch (block.type) {
      case 'combined':
        return (
          <CombinedBlockEditor 
            block={block} 
            onUpdate={(updatedBlock) => onUpdate(updatedBlock as ICombinedBlock)} 
          />
        );
      case 'text':
        return <TextBlockEditor block={block} onUpdate={onUpdate} />;
      case 'title':
        return <TitleBlockEditor block={block} onUpdate={onUpdate} />;
      case 'table':
        return <TableBlockEditor block={block} onUpdate={onUpdate} />;
      case 'list':
        return <ListBlockEditor block={block} onUpdate={onUpdate} />;
      case 'file':
        return <FileBlockEditor block={block} onUpdate={onUpdate} />;
      case 'imageGallery':
        return <ImageGalleryBlockEditor block={block} onUpdate={onUpdate} />;
      case 'divider':
        return null;
      default:
        return null;
    }
  };

  const getBlockTitle = () => {
    switch (block.type) {
      case 'combined':
        return 'Комбинированный блок';
      case 'text':
        return 'Текстовый блок';
      case 'imageGallery':
        return 'Галерея изображений';
      case 'title':
        return 'Заголовок';
      case 'table':
        return 'Таблица';
      case 'list':
        return 'Список';
      case 'file':
        return 'Файлы';
      case 'divider':
        return 'Разделитель';
      default:
        return 'Блок контента';
    }
  };

  return (
    <Box sx={{ position: 'relative', mb: 3, p: 3, border: '1px solid #ddd', borderRadius: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">
          {getBlockTitle()}
        </Typography>
        <IconButton color="error" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
      
      <Box sx={{ mb: 3 }}>
        {renderEditor()}
      </Box>
      
      <Divider sx={{ my: 3 }} />
      
      <Box>
        <Typography variant="subtitle1" sx={{ mb: 2, color: 'text.secondary' }}>
          Предпросмотр:
        </Typography>
        <Box sx={{ 
          p: 2, 
          border: '1px solid #eee', 
          borderRadius: 1,
          backgroundColor: '#fafafa'
        }}>
          <ContentBlock block={block} />
        </Box>
      </Box>
    </Box>
  );
};

export default ContentBlockEditor; 