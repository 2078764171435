import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Category } from '../../store/slices/catalogSlice';

interface DeleteCategoryModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  category: Category;
}

const DeleteCategoryModal: React.FC<DeleteCategoryModalProps> = ({ 
  open, 
  onClose, 
  onConfirm, 
  category 
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">
            Удаление категории
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography variant="body1" sx={{ mb: 3 }}>
          Вы действительно хотите удалить категорию "{category.name}"?
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button onClick={onClose}>
            Отмена
          </Button>
          <Button 
            variant="contained" 
            color="error"
            onClick={onConfirm}
          >
            Удалить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteCategoryModal; 