import React, { useState, useEffect } from 'react';
import {
  TextField,
  Autocomplete,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import { Category } from '../../store/slices/catalogSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchCategories } from '../../store/slices/catalogSlice';

interface CategorySelectProps {
  value: string;
  onChange: (categoryId: string) => void;
}

interface FlatCategory {
  id: string;
  name: string;
  fullPath: string;
  type: 'root' | 'subcategory' | 'final';
}

const CategorySelect: React.FC<CategorySelectProps> = ({ value, onChange }) => {
  const dispatch = useAppDispatch();
  const { categories, loading } = useAppSelector(state => state.catalog);
  const [flatCategories, setFlatCategories] = useState<FlatCategory[]>([]);
  
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    const flatten = (cats: Category[], parentPath = ''): FlatCategory[] => {
      return cats.reduce((acc: FlatCategory[], cat) => {
        const currentPath = parentPath ? `${parentPath} / ${cat.name}` : cat.name;
        if (cat.type === 'final') {
          acc.push({
            id: cat.id,
            name: cat.name,
            fullPath: currentPath,
            type: cat.type
          });
        }
        if (cat.children) {
          acc.push(...flatten(cat.children, currentPath));
        }
        return acc;
      }, []);
    };

    setFlatCategories(flatten(categories));
  }, [categories]);

  const selectedCategory = flatCategories.find(cat => cat.id === value);

  return (
    <Autocomplete
      value={selectedCategory || null}
      onChange={(_, newValue) => {
        onChange(newValue?.id || '');
      }}
      options={flatCategories}
      getOptionLabel={(option) => option.fullPath}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Выберите категорию"
          required
          error={!value}
          helperText={!value && "Необходимо выбрать категорию"}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography>
            {option.fullPath}
          </Typography>
        </Box>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(options, { inputValue }) => {
        const searchText = inputValue.toLowerCase();
        return options.filter(option => 
          option.fullPath.toLowerCase().includes(searchText)
        );
      }}
    />
  );
};

export default CategorySelect; 