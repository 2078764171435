import React from 'react';
import {
  Box,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Collapse,
  Button,
  Paper,
  Tooltip,
  Typography,
  Stack,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import { ITableCellData } from '../../../store/slices/productSlice';

interface TableCellEditorProps {
  cell: string | ITableCellData;
  label: string;
  onUpdate: (cell: string | ITableCellData) => void;
  onDelete: () => void;
}

const TableCellEditor: React.FC<TableCellEditorProps> = ({
  cell,
  label,
  onUpdate,
  onDelete,
}) => {
  const [showSettings, setShowSettings] = React.useState(false);
  
  const isAdvanced = typeof cell !== 'string';
  const content = typeof cell === 'string' ? cell : cell.content;
  
  const handleBasicChange = (value: string) => {
    if (isAdvanced) {
      onUpdate({ ...cell as ITableCellData, content: value });
    } else {
      onUpdate(value);
    }
  };

  const toggleAdvanced = () => {
    if (!isAdvanced) {
      onUpdate({
        content: content,
        colspan: 1,
        rowspan: 1,
        align: 'left',
        verticalAlign: 'middle',
      });
    } else {
      onUpdate(content);
    }
  };

  const getAlignIcon = (align: 'left' | 'center' | 'right') => {
    switch (align) {
      case 'left': return <FormatAlignLeftIcon />;
      case 'center': return <FormatAlignCenterIcon />;
      case 'right': return <FormatAlignRightIcon />;
    }
  };

  const getVerticalAlignIcon = (align: 'top' | 'middle' | 'bottom') => {
    switch (align) {
      case 'top': return <VerticalAlignTopIcon />;
      case 'middle': return <VerticalAlignCenterIcon />;
      case 'bottom': return <VerticalAlignBottomIcon />;
    }
  };

  return (
    <Box>
      <Paper variant="outlined" sx={{ p: 2, mb: isAdvanced ? 2 : 0 }}>
        <Box display="flex" gap={2} alignItems="center">
          <TextField
            fullWidth
            label={label}
            value={content}
            onChange={(e) => handleBasicChange(e.target.value)}
            size="small"
          />
          <Tooltip title="Настройки ячейки">
            <IconButton
              color={isAdvanced ? "primary" : "default"}
              onClick={() => setShowSettings(!showSettings)}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Удалить">
            <IconButton color="error" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Collapse in={showSettings}>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={toggleAdvanced}
              sx={{ mb: 2 }}
            >
              {isAdvanced ? 'Упростить ячейку' : 'Расширенные настройки'}
            </Button>

            {isAdvanced && (
              <Stack spacing={2}>
                <Box>
                  <Typography variant="caption" color="text.secondary" sx={{ mb: 1, display: 'block' }}>
                    Объединение ячеек
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <FormControl size="small" sx={{ minWidth: 120 }}>
                      <InputLabel>По горизонтали</InputLabel>
                      <Select
                        value={(cell as ITableCellData).colspan || 1}
                        label="По горизонтали"
                        onChange={(e) => onUpdate({
                          ...(cell as ITableCellData),
                          colspan: Number(e.target.value)
                        })}
                      >
                        {[1, 2, 3, 4, 5].map(num => (
                          <MenuItem key={num} value={num}>{num}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl size="small" sx={{ minWidth: 120 }}>
                      <InputLabel>По вертикали</InputLabel>
                      <Select
                        value={(cell as ITableCellData).rowspan || 1}
                        label="По вертикали"
                        onChange={(e) => onUpdate({
                          ...(cell as ITableCellData),
                          rowspan: Number(e.target.value)
                        })}
                      >
                        {[1, 2, 3, 4, 5].map(num => (
                          <MenuItem key={num} value={num}>{num}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Box>

                <Box>
                  <Typography variant="caption" color="text.secondary" sx={{ mb: 1, display: 'block' }}>
                    Выравнивание текста
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Box>
                      <Stack direction="row" spacing={1}>
                        {(['left', 'center', 'right'] as const).map((align) => (
                          <Tooltip key={align} title={`По ${align === 'left' ? 'левому' : align === 'center' ? 'центру' : 'правому'} краю`}>
                            <IconButton
                              size="small"
                              color={(cell as ITableCellData).align === align ? 'primary' : 'default'}
                              onClick={() => onUpdate({
                                ...(cell as ITableCellData),
                                align
                              })}
                            >
                              {getAlignIcon(align)}
                            </IconButton>
                          </Tooltip>
                        ))}
                      </Stack>
                    </Box>
                    <Box>
                      <Stack direction="row" spacing={1}>
                        {(['top', 'middle', 'bottom'] as const).map((align) => (
                          <Tooltip key={align} title={`${align === 'top' ? 'Сверху' : align === 'middle' ? 'По центру' : 'Снизу'}`}>
                            <IconButton
                              size="small"
                              color={(cell as ITableCellData).verticalAlign === align ? 'primary' : 'default'}
                              onClick={() => onUpdate({
                                ...(cell as ITableCellData),
                                verticalAlign: align
                              })}
                            >
                              {getVerticalAlignIcon(align)}
                            </IconButton>
                          </Tooltip>
                        ))}
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            )}
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};

export default TableCellEditor; 