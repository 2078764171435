import React from 'react';
import { Box, TextField, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { IFileBlock } from '../../../store/slices/productSlice';

interface FileBlockEditorProps {
  block: IFileBlock;
  onUpdate: (block: IFileBlock) => void;
}

const FileBlockEditor: React.FC<FileBlockEditorProps> = ({ block, onUpdate }) => {
  const handleFileChange = (index: number, uploadedFile: File) => {
    const newFiles = [...block.files];
    newFiles[index] = { 
      ...newFiles[index],
      name: uploadedFile.name,
      url: URL.createObjectURL(uploadedFile),
      file: uploadedFile
    };
    onUpdate({ ...block, files: newFiles });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const uploadedFile = e.target.files?.[0];
    if (uploadedFile) {
      handleFileChange(index, uploadedFile);
    }
  };

  return (
    <Box>
      <TextField
        label="Заголовок блока файлов (необязательно)"
        value={block.title || ''}
        onChange={(e) => onUpdate({ ...block, title: e.target.value })}
        fullWidth
        sx={{ mb: 2 }}
      />
      {block.files.map((file, index) => (
        <Box key={index} display="flex" gap={2} mb={2} alignItems="center">
          <TextField
            label="Название файла"
            value={file.name}
            onChange={(e) => {
              const newFiles = [...block.files];
              newFiles[index] = { ...file, name: e.target.value };
              onUpdate({ ...block, files: newFiles });
            }}
            sx={{ flex: 1 }}
          />
          <Box sx={{ flex: 2, display: 'flex', gap: 1, alignItems: 'center' }}>
            <TextField
              label="URL файла"
              value={file.url}
              onChange={(e) => {
                const newFiles = [...block.files];
                newFiles[index] = { ...file, url: e.target.value };
                onUpdate({ ...block, files: newFiles });
              }}
              sx={{ flex: 1 }}
            />
            <input
              type="file"
              style={{ display: 'none' }}
              id={`file-upload-${index}`}
              onChange={(e) => handleFileUpload(e, index)}
            />
            <label htmlFor={`file-upload-${index}`}>
              <IconButton component="span" color="primary">
                <UploadIcon />
              </IconButton>
            </label>
          </Box>
          <IconButton
            color="error"
            onClick={() => {
              onUpdate({
                ...block,
                files: block.files.filter((_, i) => i !== index)
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        onClick={() => {
          onUpdate({
            ...block,
            files: [...block.files, { name: '', url: '', file: null }]
          });
        }}
      >
        Добавить файл
      </Button>
    </Box>
  );
};

export default FileBlockEditor; 