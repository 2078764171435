import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL } from '../../config/api';

export interface Category {
  id: string;
  name: string;
  type: 'root' | 'subcategory' | 'final';
  image?: string;
  rows?: number;
  cols?: number;
  description?: string;
  children?: Category[];
}

interface CatalogState {
  categories: Category[];
  loading: boolean;
  error: string | null;
}

const initialState: CatalogState = {
  categories: [],
  loading: false,
  error: null,
};

export const fetchCategories = createAsyncThunk(
  'catalog/fetchCategories',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/api/categories`);
      return response.data as Category[];
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

interface CreateCategoryData {
  name: string;
  description: string;
  type: 'root' | 'final' | 'subcategory';
  parentId?: string;
  image: any;
}

export const createCategory = createAsyncThunk(
  'catalog/createCategory',
  async (data: CreateCategoryData) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('type', data.type);
    if (data.parentId) {
      formData.append('parentId', data.parentId);
    }
    if (data.image) {
      formData.append('image', data.image);
    }

    const response = await fetch(`/api/categories/`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Ошибка при создании категории');
    }

    const responseData = await response.json();
    return responseData;
  }
);

interface UpdateCategoryData {
  id: string;
  name: string;
  description: string;
  image: any;
  
}

export const updateCategory = createAsyncThunk(
  'catalog/updateCategory',
  async (data: UpdateCategoryData) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('description', data.description);
    if (data.image) {
      formData.append('image', data.image);
    }

    const response = await fetch(`/api/categories/${data.id}/`, {
      method: 'PUT',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Ошибка при обновлении категории');
    }

    const responseData = await response.json();
    return responseData;
  }
);

export const deleteCategory = createAsyncThunk(
  'catalog/deleteCategory',
  async (categoryId: string) => {
    const response = await fetch(`/api/categories/${categoryId}/`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error('Ошибка при удалении категории');
    }

    return categoryId;
  }
);

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload.parentId) {
          state.categories.push(action.payload);
        } else {
          const updateCategories = (categories: Category[]): Category[] => {
            return categories.map(category => {
              if (category.id === action.payload.parentId) {
                return {
                  ...category,
                  children: [...(category.children || []), action.payload]
                };
              }
              if (category.children) {
                return {
                  ...category,
                  children: updateCategories(category.children)
                };
              }
              return category;
            });
          };
          state.categories = updateCategories(state.categories);
        }
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Ошибка при создании категории';
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        const updateCategoryInTree = (categories: Category[], updatedCategory: Category): Category[] => {
          return categories.map(category => {
            if (category.id === updatedCategory.id) {
              return { ...category, ...updatedCategory };
            }
            if (category.children) {
              return {
                ...category,
                children: updateCategoryInTree(category.children, updatedCategory)
              };
            }
            return category;
          });
        };
        
        state.categories = updateCategoryInTree(state.categories, action.payload);
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        const removeCategoryFromTree = (categories: Category[]): Category[] => {
          return categories.filter(category => {
            if (category.id === action.payload) {
              return false;
            }
            if (category.children) {
              category.children = removeCategoryFromTree(category.children);
            }
            return true;
          });
        };
        
        state.categories = removeCategoryFromTree(state.categories);
      });
  },
});

export default catalogSlice.reducer; 