import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { createCategory } from '../../store/slices/catalogSlice';
import {useAppDispatch} from "../../store/hooks";

type CategoryType = 'root' | 'final' | 'subcategory';

interface AddCategoryModalProps {
  open: boolean;
  onClose: () => void;
  parentCategoryId?: string;
}

const AddCategoryModal: React.FC<AddCategoryModalProps> = ({ open, onClose, parentCategoryId }) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState<CategoryType>('subcategory');
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const isRootCategory = !parentCategoryId;

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    try {
      await dispatch(createCategory({
        name,
        description,
        type: isRootCategory ? 'root' : type,
        parentId: parentCategoryId,
        image: image
      })).unwrap();
      
      onClose();
      setName('');
      setDescription('');
      setType('subcategory');
      setImage(null);
      setImagePreview(null);
    } catch (error) {
      console.error('Failed to create category:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">
            {isRootCategory ? 'Добавить корневую категорию' : 
              parentCategoryId ? 'Добавить подкатегорию' : 'Добавить категорию'}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <TextField
          fullWidth
          label="Название"
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Описание"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="normal"
          multiline
          rows={3}
        />

        {!isRootCategory && (
          <FormControl component="fieldset" sx={{ my: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>Тип категории</Typography>
            <RadioGroup value={type} onChange={(e) => setType(e.target.value as CategoryType)}>
              <FormControlLabel 
                value="subcategory" 
                control={<Radio />} 
                label="Промежуточная (может содержать подкатегории)" 
              />
              <FormControlLabel 
                value="final" 
                control={<Radio />} 
                label="Конечная (может содержать только товары)" 
              />
            </RadioGroup>
          </FormControl>
        )}

        <Box sx={{ my: 2 }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="image-upload"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="image-upload">
            <Button
              variant="outlined"
              component="span"
              startIcon={<CloudUploadIcon />}
              fullWidth
            >
              Загрузить изображение
            </Button>
          </label>
          {imagePreview && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <img 
                src={imagePreview} 
                alt="Preview" 
                style={{ maxWidth: '100%', maxHeight: '200px' }} 
              />
            </Box>
          )}
        </Box>

        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{
            mt: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
          }}
        >
          {isRootCategory ? 'Добавить корневую категорию' : 
            parentCategoryId ? 'Добавить подкатегорию' : 'Добавить категорию'}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddCategoryModal;