import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@mui/material/styles';
import {ParallaxProvider} from 'react-scroll-parallax';
import CssBaseline from '@mui/material/CssBaseline';
import {store} from './store';
import App from './App';
import theme from "./themes/theme";
import {AnimationProvider} from './components/AnimationContext';
import {NavbarStyleProvider} from "./components/NavbarStyleContext";
import './index.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <ParallaxProvider>
                    <AnimationProvider>
                        <NavbarStyleProvider>
                            <CssBaseline/>
                            <App/>
                        </NavbarStyleProvider>
                    </AnimationProvider>
                </ParallaxProvider>
            </ThemeProvider>
        </React.StrictMode>
    </Provider>
);