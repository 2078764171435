import React from 'react';
import Slider, { Settings } from "react-slick";
import { Box, GlobalStyles, IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface CarouselListProps {
  children: React.ReactNode[];
  slidesToShow?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}

const CarouselList: React.FC<CarouselListProps> = ({ children, slidesToShow }) => {
  const sliderRef = React.useRef<Slider>(null);

  const getCurrentSlidesToShow = () => {
    if (typeof window !== 'undefined') {
      const width = window.innerWidth;
      if (width >= 1536) return slidesToShow?.xl || slidesToShow?.lg || 4;
      if (width >= 1200) return slidesToShow?.lg || slidesToShow?.md || 3;
      if (width >= 900) return slidesToShow?.md || slidesToShow?.sm || 2;
      if (width >= 600) return slidesToShow?.sm || slidesToShow?.xs || 1;
      return slidesToShow?.xs || 1;
    }
    return slidesToShow?.xl || slidesToShow?.lg || slidesToShow?.md || slidesToShow?.sm || slidesToShow?.xs || 4;
  };

  const [currentSlidesToShow, setCurrentSlidesToShow] = React.useState(getCurrentSlidesToShow());

  React.useEffect(() => {
    const handleResize = () => {
      setCurrentSlidesToShow(getCurrentSlidesToShow());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [slidesToShow]);

  // Вычисляем количество пустых слайдов для выравнивания вправо
  const paddingCount = Math.max(currentSlidesToShow - children.length, 0);

  // Создаем массив с добавленными пустыми слайдами
  const paddedChildren = [...children, ...Array(paddingCount).fill(null)];

  // Состояния для отслеживания текущего слайда
  const [currentSlide, setCurrentSlide] = React.useState(0);

  // Общее количество слайдов
  const totalSlides = paddedChildren.length;

  // Функция для обработки смены слайда
  const handleAfterChange = (current: number) => {
    setCurrentSlide(current);
  };

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: currentSlidesToShow,
    slidesToScroll: 1, // Можно оставить 1, т.к. swipeToSlide позволяет пролистывать несколько слайдов
    arrows: false, // Отключаем стандартные стрелки, используем кастомные
    centerMode: false,
    swipeToSlide: true, // Позволяет пролистывать несколько слайдов за одно перетаскивание
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1536, // xl
        settings: {
          slidesToShow: slidesToShow?.lg || 4,
        },
      },
      {
        breakpoint: 1200, // lg
        settings: {
          slidesToShow: slidesToShow?.md || 3,
        },
      },
      {
        breakpoint: 900, // md
        settings: {
          slidesToShow: slidesToShow?.sm || 2,
        },
      },
      {
        breakpoint: 600, // sm
        settings: {
          slidesToShow: slidesToShow?.xs || 1,
        },
      },
    ],
  };

  const goToPrev = () => {
    sliderRef.current?.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current?.slickNext();
  };

  // Определяем, нужно ли отключить стрелки
  const isPrevDisabled = currentSlide === 0;
  const isNextDisabled = currentSlide >= totalSlides - currentSlidesToShow;

  return (
    <>
      <GlobalStyles
        styles={{
          '.slick-slide': {
            display: 'flex',
            height: 'unset',
            alignItems: 'stretch',
            alignContent: 'stretch',
          },
          '.slick-slide > div': {
            display: 'flex',
            alignItems: 'stretch',
            alignContent: 'stretch',
            width: '100%',
            height: '100%',
          },
          '.slick-track': {
            display: 'flex',
            alignItems: 'stretch',
            alignContent: 'stretch',
            padding: 20,
          },
        }}
      />
      <Box sx={{ position: 'relative', padding: '0 40px' }}>
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            left: '-20px',
            zIndex: 1,
            transform: 'translateY(-50%)',
            color: isPrevDisabled ? 'grey.400' : 'inherit', // Визуальное отключение
          }}
          onClick={goToPrev}
          disabled={isPrevDisabled}
        >
          <ArrowBackIosNew />
        </IconButton>

        <Slider ref={sliderRef} {...settings}>
          {paddedChildren.map((child, index) => (
            <div key={index}>
              {child ? (
                child
              ) : (
                // Пустой слайд, который занимает место, но не отображает контент
                <Box sx={{ visibility: 'hidden', width: '100%' }} />
              )}
            </div>
          ))}
        </Slider>

        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            right: '-20px',
            zIndex: 1,
            transform: 'translateY(-50%)',
            color: isNextDisabled ? 'grey.400' : 'inherit', // Визуальное отключение
          }}
          onClick={goToNext}
          disabled={isNextDisabled}
        >
          <ArrowForwardIos />
        </IconButton>
      </Box>
    </>
  );
};

export default CarouselList;
