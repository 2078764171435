import React from 'react';
import { Box, Button } from '@mui/material';
import { IImageGalleryBlock } from '../../../store/slices/productSlice';
import ImageUploader from "../../Common/ImageUploader";

interface ImageGalleryBlockEditorProps {
  block: IImageGalleryBlock;
  onUpdate: (block: IImageGalleryBlock) => void;
}

const ImageGalleryBlockEditor: React.FC<ImageGalleryBlockEditorProps> = ({ block, onUpdate }) => {
  return (
    <Box>
      {block.images.map((image, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <ImageUploader
            value={image}
            file={block.imageFiles?.[index]}
            onChange={(url, file) => {
              const newImages = [...block.images];
              const newFiles = [...(block.imageFiles || Array(block.images.length).fill(null))];
              if (file) {
                newImages[index] = URL.createObjectURL(file);
              } else {
                newImages[index] = url;
              }
              newFiles[index] = file;
              onUpdate({
                ...block,
                images: newImages,
                imageFiles: newFiles
              });
            }}
            onDelete={() => {
              onUpdate({
                ...block,
                images: block.images.filter((_, i) => i !== index),
                imageFiles: (block.imageFiles || []).filter((_, i) => i !== index)
              });
            }}
          />
        </Box>
      ))}
      <Button
        variant="outlined"
        onClick={() => {
          onUpdate({
            ...block,
            images: [...block.images, ''],
            imageFiles: [...(block.imageFiles || []), null]
          });
        }}
      >
        Добавить изображение
      </Button>
    </Box>
  );
};

export default ImageGalleryBlockEditor; 