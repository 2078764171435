import React, { useRef } from 'react';
import { Box, TextField, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

interface ImageUploaderProps {
  value: string;
  file: File | null;
  onChange: (url: string, file: File | null) => void;
  onDelete?: () => void;
  label?: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  value,
  file,
  onChange,
  onDelete,
  label
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      onChange(imageUrl, file);
    }
  };

  return (
    <Box display="flex" gap={2} alignItems="center">
      <Box 
        sx={{ 
          width: 60, 
          height: 60, 
          border: '1px dashed grey',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden'
        }}
        onClick={() => fileInputRef.current?.click()}
      >
        {value ? (
          <img 
            src={value} 
            alt="Preview" 
            style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
          />
        ) : (
          <AddPhotoAlternateIcon />
        )}
      </Box>
      
      <TextField
        fullWidth
        label={label || "URL изображения"}
        value={value}
        onChange={(e) => onChange(e.target.value, null)}
      />

      {onDelete && (
        <IconButton color="error" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }}
      />

      {file && (
        <Typography variant="caption" color="textSecondary">
          Файл: {file.name}
        </Typography>
      )}
    </Box>
  );
};

export default ImageUploader; 