import React, {useEffect, useState} from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { IContentBlock } from '../store/slices/productSlice';
import ContentBlockEditor from '../components/Product/ContentBlockEditor';
import AddBlockMenu from '../components/Product/AddBlockMenu';
import {useNavbarStyle} from "../components/NavbarStyleContext";
import CategorySelect from '../components/Product/CategorySelect';
import ProductPreviewPage from '../components/Product/ProductPreviewPage';
import ImageUploader from "../components/Common/ImageUploader";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { createProduct, updateProduct } from '../store/slices/productSlice';
import { useNavigate } from 'react-router-dom';
import {useAppDispatch} from "../store/hooks";

interface ProductFormData {
  name: string;
  image: string;
  description: string;
  categoryId: string;
  card_specs: { [key: string]: string };
  mainImages: string[];
  contentBlocks: IContentBlock[];
  imageFile: File | null;
  mainImageFiles: (File | null)[];
}

interface AddProductPageProps {
  isEditing?: boolean;
  productId?: string;
  initialData?: ProductFormData;
}

const AddProductPage: React.FC<AddProductPageProps> = ({ 
  isEditing = false, 
  productId,
  initialData 
}) => {
  const [formData, setFormData] = useState<ProductFormData>(
    initialData || {
      name: '',
      image: '',
      description: '',
      categoryId: new URLSearchParams(window.location.search).get('categoryId') || '',
      card_specs: {},
      mainImages: [],
      contentBlocks: [],
      imageFile: null,
      mainImageFiles: [],
    }
  );
  const { setNavbarStyle } = useNavbarStyle();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  console.log(formData)

  useEffect(() => {
    setNavbarStyle('light');

    return () => {
      setNavbarStyle('dark');
    };
  }, [setNavbarStyle]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleBasicInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleAddMainImage = () => {
    setFormData(prev => ({
      ...prev,
      mainImages: [...prev.mainImages, '']
    }));
  };

  const handleMainImageChange = (index: number, url: string, file: File | null) => {
    const newMainImages = [...formData.mainImages];
    const newMainImageFiles = [...formData.mainImageFiles];
    newMainImages[index] = url;
    newMainImageFiles[index] = file;
    setFormData(prev => ({
      ...prev,
      mainImages: newMainImages,
      mainImageFiles: newMainImageFiles
    }));
  };

  const handleRemoveMainImage = (index: number) => {
    setFormData(prev => ({
      ...prev,
      mainImages: prev.mainImages.filter((_, i) => i !== index)
    }));
  };

  const handleAddContentBlock = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBlockTypeSelect = (type: string) => {
    let newBlock: IContentBlock;

    switch (type) {
      case 'text':
        newBlock = { type: 'text', content: '', title: '' };
        break;
      case 'title':
        newBlock = { type: 'title', text: '', level: 2 };
        break;
      case 'table':
        newBlock = { type: 'table', headers: [], rows: [], title: '' };
        break;
      case 'list':
        newBlock = { type: 'list', items: [], title: '' };
        break;
      case 'imageGallery':
        newBlock = { type: 'imageGallery', images: [], imageFiles: [] };
        break;
      case 'file':
        newBlock = { type: 'file', files: [], title: '' };
        break;
      case 'divider':
        newBlock = { type: 'divider' };
        break;
      case 'combined':
        newBlock = { 
          type: 'combined', 
          content: [] 
        };
        break;
      default:
        return;
    }

    setFormData(prev => ({
      ...prev,
      contentBlocks: [...prev.contentBlocks, newBlock]
    }));
    setAnchorEl(null);
  };

  const handleUpdateBlock = (index: number, updatedBlock: IContentBlock) => {
    setFormData(prev => ({
      ...prev,
      contentBlocks: prev.contentBlocks.map((block, i) => 
        i === index ? updatedBlock : block
      )
    }));
  };

  const handleDeleteBlock = (index: number) => {
    setFormData(prev => ({
      ...prev,
      contentBlocks: prev.contentBlocks.filter((_, i) => i !== index)
    }));
  };

  const handleMoveBlock = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= formData.contentBlocks.length) return;

    setFormData(prev => {
      const newBlocks = [...prev.contentBlocks];
      const [movedBlock] = newBlocks.splice(fromIndex, 1);
      newBlocks.splice(toIndex, 0, movedBlock);
      
      return {
        ...prev,
        contentBlocks: newBlocks
      };
    });
  };

  const handleSave = async () => {
    try {
      if (isEditing && productId) {
        await dispatch(updateProduct({ productId, data: formData })).unwrap();
      } else {
        await dispatch(createProduct(formData)).unwrap();
      }
      navigate(`/catalog/${formData.categoryId}`);
    } catch (error) {
      console.error('Failed to save product:', error);
    }
  };

  return (
    <Container maxWidth="lg">
      <ProductPreviewPage
          formData={{
            id: 'preview',
            name: formData.name,
            image: formData.image,
            description: formData.description,
            categoryId: formData.categoryId,
            card_specs: formData.card_specs,
            mainImages: formData.mainImages,
            contentBlocks: formData.contentBlocks
          }}
      />
      <Box py={4}>
        <Typography variant="h4" gutterBottom>
          {isEditing ? "Редактирование продукта" : "Добавление нового товара" }
        </Typography>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Основная информация
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Название товара"
              name="name"
              value={formData.name}
              onChange={handleBasicInfoChange}
              fullWidth
            />
            <ImageUploader
              label="Основное изображение"
              value={formData.image}
              file={formData.imageFile}
              onChange={(url, file) => setFormData(prev => ({
                ...prev,
                image: url,
                imageFile: file
              }))}
            />
            <TextField
              label="Описание"
              name="description"
              multiline
              rows={4}
              value={formData.description}
              onChange={handleBasicInfoChange}
              fullWidth
            />
            <CategorySelect
              value={formData.categoryId}
              onChange={(categoryId) => setFormData(prev => ({
                ...prev,
                categoryId
              }))}
            />
          </Box>
        </Paper>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">
              Галерея изображений
            </Typography>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddMainImage}
              variant="contained"
            >
              Добавить изображение
            </Button>
          </Box>
          
          {formData.mainImages.map((image, index) => (
            <ImageUploader
              key={index}
              label={`Изображение ${index + 1}`}
              value={image}
              file={formData.mainImageFiles[index]}
              onChange={(url, file) => handleMainImageChange(index, url, file)}
              onDelete={() => handleRemoveMainImage(index)}
            />
          ))}
        </Paper>

        <Paper sx={{ p: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">
              Контент
            </Typography>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddContentBlock}
              variant="contained"
            >
              Добавить блок
            </Button>
          </Box>
          
          {formData.contentBlocks.map((block, index) => (
            <Box key={index} sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute', right: -40, top: 10, display: 'flex', flexDirection: 'column' }}>
                <IconButton 
                  size="small"
                  onClick={() => handleMoveBlock(index, index - 1)}
                  disabled={index === 0}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton 
                  size="small"
                  onClick={() => handleMoveBlock(index, index + 1)}
                  disabled={index === formData.contentBlocks.length - 1}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Box>
              <ContentBlockEditor
                key={index}
                block={block}
                onUpdate={(updatedBlock) => handleUpdateBlock(index, updatedBlock)}
                onDelete={() => handleDeleteBlock(index)}
              />
            </Box>
          ))}
        </Paper>
      </Box>

      <AddBlockMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onSelect={handleBlockTypeSelect}
      />

      <Box sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSave}
        >
          Сохранить продукт
        </Button>
      </Box>
    </Container>
  );
};

export default AddProductPage; 