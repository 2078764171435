import React from 'react';
import { Box, TextField, Select, MenuItem } from '@mui/material';
import { ITitle } from '../../../store/slices/productSlice';

interface TitleBlockEditorProps {
  block: ITitle;
  onUpdate: (block: ITitle) => void;
}

const TitleBlockEditor: React.FC<TitleBlockEditorProps> = ({ block, onUpdate }) => {
  return (
    <Box>
      <TextField
        label="Текст заголовка"
        value={block.text}
        onChange={(e) => onUpdate({ ...block, text: e.target.value })}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Select
        value={block.level}
        onChange={(e) => onUpdate({ ...block, level: Number(e.target.value) })}
        fullWidth
      >
        <MenuItem value={1}>Заголовок 1</MenuItem>
        <MenuItem value={2}>Заголовок 2</MenuItem>
        <MenuItem value={3}>Заголовок 3</MenuItem>
        <MenuItem value={4}>Заголовок 4</MenuItem>
      </Select>
    </Box>
  );
};

export default TitleBlockEditor; 