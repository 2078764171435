import React from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper,
  IconButton,
  Tooltip,
  Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ITableBlock } from '../../../store/slices/productSlice';
import TableCellEditor from './TableCellEditor';

interface TableBlockEditorProps {
  block: ITableBlock;
  onUpdate: (block: ITableBlock) => void;
}

const TableBlockEditor: React.FC<TableBlockEditorProps> = ({ block, onUpdate }) => {
  return (
    <Box>
      <TextField
        label="Заголовок таблицы (необязательно)"
        value={block.title || ''}
        onChange={(e) => onUpdate({ ...block, title: e.target.value })}
        fullWidth
        sx={{ mb: 3 }}
      />
      
      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 2, color: 'text.primary', fontWeight: 500 }}>
          Заголовки таблицы
        </Typography>
        <Box sx={{ pl: 2 }}>
          {block.headers.map((header, index) => (
            <Box 
              key={index} 
              sx={{ 
                mb: 1,
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  '& .drag-handle': {
                    opacity: 1,
                  }
                }
              }}
            >
              <TableCellEditor
                cell={header}
                label={`Заголовок ${index + 1}`}
                onUpdate={(updatedCell) => {
                  const newHeaders = [...block.headers];
                  newHeaders[index] = updatedCell;
                  onUpdate({ ...block, headers: newHeaders });
                }}
                onDelete={() => {
                  onUpdate({
                    ...block,
                    headers: block.headers.filter((_, i) => i !== index)
                  });
                }}
              />
            </Box>
          ))}
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              onUpdate({
                ...block,
                headers: [...block.headers, '']
              });
            }}
            sx={{ mt: 1 }}
          >
            Добавить заголовок
          </Button>
        </Box>
      </Paper>

      <Paper variant="outlined" sx={{ p: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 2, color: 'text.primary', fontWeight: 500 }}>
          Строки таблицы
        </Typography>
        <Box sx={{ pl: 2 }}>
          {block.rows.map((row, rowIndex) => (
            <Paper 
              key={rowIndex} 
              variant="outlined"
              sx={{ 
                mb: 2, 
                p: 2,
                position: 'relative',
                '&:hover': {
                  '& .row-actions': {
                    opacity: 1
                  }
                }
              }}
            >
              <Box 
                className="row-actions"
                sx={{ 
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  opacity: 0,
                  transition: 'opacity 0.2s'
                }}
              >
                <Tooltip title="Удалить строку">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => {
                      onUpdate({
                        ...block,
                        rows: block.rows.filter((_, i) => i !== rowIndex)
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              
              <Typography variant="subtitle2" sx={{ mb: 2, color: 'text.secondary' }}>
                Строка {rowIndex + 1}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box display="flex" flexDirection="column" gap={1}>
                {row.map((cell, cellIndex) => (
                  <TableCellEditor
                    key={cellIndex}
                    cell={cell}
                    label={`Ячейка ${cellIndex + 1}`}
                    onUpdate={(updatedCell) => {
                      const newRows = [...block.rows];
                      newRows[rowIndex][cellIndex] = updatedCell;
                      onUpdate({ ...block, rows: newRows });
                    }}
                    onDelete={() => {
                      const newRows = [...block.rows];
                      newRows[rowIndex] = row.filter((_, i) => i !== cellIndex);
                      onUpdate({ ...block, rows: newRows });
                    }}
                  />
                ))}
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    const newRows = [...block.rows];
                    newRows[rowIndex] = [...row, ''];
                    onUpdate({ ...block, rows: newRows });
                  }}
                >
                  Добавить ячейку
                </Button>
              </Box>
            </Paper>
          ))}
          <Button
            variant="outlined"
            onClick={() => {
              onUpdate({
                ...block,
                rows: [...block.rows, Array(block.headers.length).fill('')]
              });
            }}
            sx={{ mt: 1 }}
          >
            Добавить строку
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default TableBlockEditor; 