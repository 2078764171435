import React from 'react';
import { Box, TextField } from '@mui/material';
import { ITextBlock } from '../../../store/slices/productSlice';

interface TextBlockEditorProps {
  block: ITextBlock;
  onUpdate: (block: ITextBlock) => void;
}

const TextBlockEditor: React.FC<TextBlockEditorProps> = ({ block, onUpdate }) => {
  return (
    <Box>
      <TextField
        label="Заголовок (необязательно)"
        value={block.title || ''}
        onChange={(e) => onUpdate({ ...block, title: e.target.value })}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Текст"
        multiline
        rows={4}
        value={block.content}
        onChange={(e) => onUpdate({ ...block, content: e.target.value })}
        fullWidth
      />
    </Box>
  );
};

export default TextBlockEditor; 