// src/App.tsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CatalogPage from './pages/CatalogPage';
import ScrollToTop from './components/ScrollToTop';
import ProductDetailsPage from './pages/ProductDetailsPage';
import ErrorPage from './pages/ErrorPage';
import BackToTopButton from './components/BackToTopButton';
import Navbar from './components/Navbar/Navbar';
import AddProductPage from "./pages/AddProductPage";
import EditProductPage from "./pages/EditProductPage";

const App: React.FC = () => {
    return (
        <Router>
            <ScrollToTop />
            <Navbar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/catalog/:categoryId" element={<CatalogPage />} />
                <Route path="/catalog" element={<CatalogPage />} />
                <Route path="/products/:productId" element={<ProductDetailsPage />} />
                <Route path="/products/:productId/edit" element={<EditProductPage />} />
                <Route path="/add_product" element={<AddProductPage />} />
                <Route path="*" element={<ErrorPage />} />
            </Routes>
            <BackToTopButton />
        </Router>
    );
};

export default App;
