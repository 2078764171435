import React from 'react';
import { Box, TextField, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IListBlock } from '../../../store/slices/productSlice';

interface ListBlockEditorProps {
  block: IListBlock;
  onUpdate: (block: IListBlock) => void;
}

const ListBlockEditor: React.FC<ListBlockEditorProps> = ({ block, onUpdate }) => {
  return (
    <Box>
      <TextField
        label="Заголовок списка (необязательно)"
        value={block.title || ''}
        onChange={(e) => onUpdate({ ...block, title: e.target.value })}
        fullWidth
        sx={{ mb: 2 }}
      />
      {block.items.map((item, index) => (
        <Box key={index} display="flex" gap={2} mb={1}>
          <TextField
            fullWidth
            label={`Элемент ${index + 1}`}
            value={item}
            onChange={(e) => {
              const newItems = [...block.items];
              newItems[index] = e.target.value;
              onUpdate({ ...block, items: newItems });
            }}
          />
          <IconButton
            color="error"
            onClick={() => {
              onUpdate({
                ...block,
                items: block.items.filter((_, i) => i !== index)
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        onClick={() => {
          onUpdate({
            ...block,
            items: [...block.items, '']
          });
        }}
      >
        Добавить элемент
      </Button>
    </Box>
  );
};

export default ListBlockEditor; 