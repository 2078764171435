import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Button, IconButton } from '@mui/material';
import { Parallax } from 'react-scroll-parallax';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { deleteProduct, fetchProductInfo } from '../../store/slices/productSlice';
import { fetchCategories, Category } from '../../store/slices/catalogSlice';
import HoverLink from '../HoverLink';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';

interface ProductHeaderProps {
  productId: string;
  scrollToContent: () => void;
}

const ProductHeader: React.FC<ProductHeaderProps> = ({ productId, scrollToContent }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const productState = useSelector((state: RootState) => state.products.productInfo[productId]);
  const categoriesState = useSelector((state: RootState) => state.catalog.categories);

  const product = productState?.product;
  const loading = productState?.loading;

  const [categoryPath, setCategoryPath] = useState<Category[]>([]);

  useEffect(() => {
    if (!product && !loading) {
      dispatch(fetchProductInfo(productId));
    }

    if (!categoriesState.length) {
      dispatch(fetchCategories());
    }

    if (product && categoriesState.length) {
      const path = findCategoryPath(product.categoryId, categoriesState);
      setCategoryPath(path);
    }
  }, [dispatch, productId, product, categoriesState, loading]);

  const findCategoryPath = (categoryId: string, categories: Category[]): Category[] => {
    for (const category of categories) {
      if (category.id === categoryId) {
        return [category];
      }
      if (category.children) {
        const childPath = findCategoryPath(categoryId, category.children);
        if (childPath.length) {
          return [category, ...childPath];
        }
      }
    }
    return [];
  };

  const handleDelete = async () => {
    if (window.confirm('Вы уверены, что хотите удалить этот продукт?')) {
      try {
        await dispatch(deleteProduct(productId)).unwrap();
        navigate(`/catalog/${product?.categoryId}`);
      } catch (error) {
        console.error('Failed to delete product:', error);
      }
    }
  };

  if (loading || !product) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          zIndex: -1,
          overflow: 'hidden',
          '::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          },
        }}
      >
        <Parallax speed={-50} style={{ minHeight: '100vh', width: '100%' }}>
          {product.image ? (
            <Box
              sx={{
                backgroundImage: `url(${product.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                height: '100vh',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: -1,
              }}
            />
          ) : (
            <video
              autoPlay
              muted
              loop
              style={{
                width: '100%',
                height: '100vh',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: -1,
              }}
            >
              <source src="/videos/default_product.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Parallax>
      </Box>

      <Container maxWidth="xl" sx={{ paddingBottom: '2vh', position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '20px',
            left: '20px',
            zIndex: 2,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            color: '#fff',
          }}
        >
          <HoverLink
            to="/catalog"
            label="Каталог"
            color="inherit"
            underlineColor="white"
            variant="body1"
            sx={{
              fontWeight: '500',
              fontSize: { xs: '12px', sm: '14px', md: '18px' }
            }}
          />
          {categoryPath.map((category, index) => (
            <React.Fragment key={category.id}>
              <Typography variant="body1" sx={{ margin: '0 8px', color: '#fff' }}>
                /
              </Typography>
              <HoverLink
                to={`/catalog/${category.id}`}
                label={category.name}
                color="inherit"
                underlineColor="white"
                variant="body1"
                sx={{
                  fontWeight: '500',
                  fontSize: { xs: '12px', sm: '14px', md: '18px' }
                }}
              />
            </React.Fragment>
          ))}
          <Typography variant="body1" sx={{ margin: '0 8px', color: '#fff' }}>
            /
          </Typography>
          <HoverLink
            to={`/products/${productId}`}
            label={product.name}
            color="inherit"
            underlineColor="white"
            variant="body1"
            sx={{
              fontWeight: '500',
              fontSize: { xs: '12px', sm: '14px', md: '18px' }
            }}
          />
          <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}>
            <IconButton
              component={Link}
              to={`/products/${productId}/edit`}
              sx={{ 
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(5px)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                }
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDelete}
              sx={{ 
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(5px)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>

        <Box p={2}>
          <Container
            sx={{
              paddingTop: '20vh',
              width: { xs: 'auto', sm: 500, md: 700 },
              height: '90vh',
              borderRadius: 4,
              zIndex: 1,
              color: '#fff',
              textAlign: 'center',
            }}
          >
            <Typography
              gutterBottom
              sx={{ fontWeight: '800', fontSize: { xs: '5vw', sm: '36px', md: '42px' } }}
            >
              {product.name}
            </Typography>
            <Typography
              variant="h5"
              sx={{ textAlign: 'center', fontSize: { xs: '4vw', sm: '18px', md: '24px' } }}
            >
              {product.description || 'Описание продукта'}
            </Typography>
            <Button
              variant="contained"
              onClick={scrollToContent}
              sx={{
                mt: '25%',
                color: 'black',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '10px',
                backdropFilter: 'blur(5px)',
                padding: '10px 20px',
                fontSize: '16px',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                },
              }}
            >
              Узнать больше
            </Button>
          </Container>
        </Box>
      </Container>
    </>
  );
};

export default ProductHeader;
