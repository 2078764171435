import React, {useRef, useState, useEffect} from 'react';
import {Box, Typography, Slide} from '@mui/material';
import {Link} from 'react-router-dom';
import CatalogMenu from './CatalogMenu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HoverLink from '../HoverLink';
import {useNavbarStyle} from '../NavbarStyleContext';

interface NavbarLinksProps {
    isSticky: boolean;
}

const NavbarLinks: React.FC<NavbarLinksProps> = ({isSticky}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const catalogButtonRef = useRef<HTMLElement | null>(null);
    const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const {navbarStyle} = useNavbarStyle();

    useEffect(() => {
        setShowLinks(true);
    }, []);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
            closeTimeoutRef.current = null;
        }
        setIsMenuOpen(true);
        catalogButtonRef.current = event.currentTarget;
    };

    const handleMenuClose = (delay = 300) => {
        if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
        }
        closeTimeoutRef.current = setTimeout(() => {
            setIsMenuOpen(false);
        }, delay);
    };

    const handleImmediateClose = () => {
        if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
        }
        setIsMenuOpen(false);
    };

    const isLightStyle = navbarStyle === 'light';
    const textColor = isLightStyle ? 'black' : 'white';

    return (
        <Box
            sx={{
                display: {xs: 'none', lg: 'flex'},
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '20px',
                marginRight: {
                    xs: 0,
                    lg: 'auto',
                },
            }}
        >
            <Slide in={showLinks} direction="down" timeout={{enter: 500}} style={{transitionDelay: '100ms'}}>
                <Box
                    sx={{
                        display: {xs: 'none', lg: 'flex'},
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 999,
                        width: 150,
                        height: 80,
                        position: 'relative',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                    onMouseEnter={(event) => {
                        handleMenuOpen(event);
                    }}
                    onMouseLeave={() => {
                        handleMenuClose(100);
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                width: '24px',
                                height: '24px',
                                mr: 1,
                            }}
                        >
                            <MenuIcon
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    opacity: isMenuOpen ? 0 : 1,
                                    transform: isMenuOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                                    transition: 'opacity 0.3s, transform 0.3s',
                                    color: isSticky ? 'black' : 'inherit',
                                }}
                            />
                            <MenuOpenIcon
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    opacity: isMenuOpen ? 1 : 0,
                                    transform: isMenuOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                                    transition: 'opacity 0.3s, transform 0.3s',
                                    color: isSticky ? 'black' : 'inherit',
                                }}
                            />
                        </Box>
                        <Typography variant="h6">
                            <HoverLink
                                to="/catalog"
                                label="Каталог"
                                color={isSticky ? 'black' : 'inherit'}
                                underlineColor={isSticky ? 'black' : textColor}
                                variant="h6"
                            />
                        </Typography>
                    </Box>
                </Box>
            </Slide>

            <CatalogMenu
                anchorEl={catalogButtonRef.current}
                isOpen={isMenuOpen}
                onClose={() => {
                    if (closeTimeoutRef.current) {
                        clearTimeout(closeTimeoutRef.current);
                        closeTimeoutRef.current = null;
                    }
                    setIsMenuOpen(false);
                }}
                onMouseEnter={() => {
                    if (closeTimeoutRef.current) {
                        clearTimeout(closeTimeoutRef.current);
                        closeTimeoutRef.current = null;
                    }
                }}
                onMouseLeave={() => handleMenuClose(1000)}
            />
        </Box>
    );
};

export default NavbarLinks;
