import React, {useEffect, useRef, useState} from 'react';
import {
    Typography,
    Box,
    Container,
    CircularProgress,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {fetchCategories} from '../store/slices/catalogSlice';
import {fetchProductsByCategory} from '../store/slices/productSlice';
import {useParams, useNavigate} from 'react-router-dom';
import {Category} from '../store/slices/catalogSlice';
import CatalogHeader from '../components/Catalog/CatalogHeader';
import ProductList from '../components/Catalog/ProductList';
import CategoryList from '../components/Catalog/CategoryList';
import HoverLink from '../components/HoverLink';
import AddCategoryModal from '../components/Modals/AddCategoryModal';
import EditCategoryModal from '../components/Modals/EditCategoryModal';
import DeleteCategoryModal from '../components/Modals/DeleteCategoryModal';
import {deleteCategory} from '../store/slices/catalogSlice';

const CatalogPage: React.FC = () => {
    const {categoryId} = useParams<{ categoryId?: string }>();
    const dispatch = useAppDispatch();
    const {categories, loading, error} = useAppSelector((state) => state.catalog);
    const productsByCategory = useAppSelector((state) => state.products.byCategory);

    const catalogRef = useRef<HTMLDivElement | null>(null);
    const navBarHeight = 90;

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedParentId, setSelectedParentId] = useState<string | undefined>(undefined);
    const [categoryToEdit, setCategoryToEdit] = useState<Category | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState<Category | null>(null);

    const navigate = useNavigate();

    const handleAddProduct = (categoryId: string) => {
        navigate(`/add_product?categoryId=${categoryId}`);
    };

    useEffect(() => {
        if (categories.length === 0) {
            dispatch(fetchCategories());
        }
    }, [dispatch, categories.length]);

    const scrollToContent = () => {
        if (catalogRef.current) {
            const offsetTop =
                catalogRef.current.getBoundingClientRect().top +
                window.pageYOffset -
                navBarHeight;
            window.scrollTo({top: offsetTop, behavior: 'smooth'});
        }
    };

    const handleOpenAddModal = (parentId?: string) => {
        setSelectedParentId(parentId);
        setIsAddModalOpen(true);
    };

    const handleDeleteClick = (category: Category) => {
        if (!canDeleteCategory(category)) {
            return;
        }
        setCategoryToDelete(category);
        setIsDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (categoryToDelete) {
            try {
                await dispatch(deleteCategory(categoryToDelete.id)).unwrap();
                setIsDeleteModalOpen(false);
                setCategoryToDelete(null);
            } catch (error) {
                console.error('Failed to delete category:', error);
            }
        }
    };

    if (loading) {
        return <CircularProgress/>;
    }

    if (error) {
        return <Typography color="error">Ошибка: {error}</Typography>;
    }

    const findCategoryById = (
        id: string,
        categoryList: Category[],
    ): Category | null => {
        for (const category of categoryList) {
            if (category.id === id) {
                return category;
            }
            if (category.children) {
                const found = findCategoryById(id, category.children);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    const findCategoryPathById = (
        id: string,
        categoryList: Category[],
        path: Category[] = []
    ): Category[] | null => {
        for (const category of categoryList) {
            const newPath = [...path, category];
            if (category.id === id) {
                return newPath;
            }
            if (category.children) {
                const found = findCategoryPathById(id, category.children, newPath);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    const selectedCategory = categoryId
        ? findCategoryById(categoryId, categories)
        : null;

    if (categoryId && !selectedCategory) {
        return <Typography>Категория не найдена</Typography>;
    }

    const isFinalCategory = (category: Category) => category.type === 'final';

    const loadProductsForCategory = (categoryId: string) => {
        const categoryData = productsByCategory[categoryId];
        if (!categoryData && !productsLoading(categoryId)) {
            dispatch(fetchProductsByCategory(categoryId));
        }
        return categoryData?.products || [];
    };

    const productsLoading = (categoryId: string) => {
        return productsByCategory[categoryId]?.loading || false;
    };

    const productsError = (categoryId: string) => {
        return productsByCategory[categoryId]?.error || null;
    };

    const categoryPath = categoryId
        ? findCategoryPathById(categoryId, categories)
        : null;

    const canDeleteCategory = (category: Category): boolean => {
        if (category.children && category.children.length > 0) {
            return false;
        }

        if (category.type === 'final') {
            const products = productsByCategory[category.id]?.products;
            return !products || products.length === 0;
        }

        return true;
    };

    return (
        <>
            <AddCategoryModal
                open={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                parentCategoryId={selectedParentId}
            />
            {categoryToEdit && (
                <EditCategoryModal
                    open={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        setCategoryToEdit(null);
                    }}
                    category={categoryToEdit}
                />
            )}
            {categoryToDelete && (
                <DeleteCategoryModal
                    open={isDeleteModalOpen}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                        setCategoryToDelete(null);
                    }}
                    onConfirm={handleConfirmDelete}
                    category={categoryToDelete}
                />
            )}
            <CatalogHeader
                categoryPath={categoryPath}
                selectedCategory={selectedCategory}
                scrollToContent={scrollToContent}
                onEditClick={(category) => {
                    setCategoryToEdit(category);
                    setIsEditModalOpen(true);
                }}
                onDeleteClick={handleDeleteClick}
                productsByCategory={productsByCategory}
            />

            <Container maxWidth="xl">
                <Box p={2}>
                    <Box ref={catalogRef}>
                        {!selectedCategory && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 4
                                }}
                            >
                                <Typography variant="h4">Все категории</Typography>
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon/>}
                                    sx={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                        backdropFilter: 'blur(5px)',
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                            transform: 'translateY(-2px)',
                                        },
                                    }}
                                    onClick={() => handleOpenAddModal()}
                                >
                                    Добавить категорию
                                </Button>
                            </Box>
                        )}

                        {selectedCategory ? (
                            isFinalCategory(selectedCategory) ? (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mb: 4
                                        }}
                                    >
                                        <Typography variant="h4">
                                            Товары категории "{selectedCategory.name}"
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            startIcon={<AddIcon/>}
                                            sx={{
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                backdropFilter: 'blur(5px)',
                                                padding: '10px 20px',
                                                fontSize: '16px',
                                                transition: 'all 0.3s ease',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                                    transform: 'translateY(-2px)',
                                                },
                                            }}
                                            onClick={() => handleAddProduct(selectedCategory.id)}
                                        >
                                            Добавить продукт
                                        </Button>
                                    </Box>
                                    <Box>
                                        {productsLoading(selectedCategory.id) ? (
                                            <CircularProgress/>
                                        ) : productsError(selectedCategory.id) ? (
                                            <Typography color="error">
                                                {`Ошибка загрузки товаров: ${productsError(selectedCategory.id)}`}
                                            </Typography>
                                        ) : loadProductsForCategory(selectedCategory.id).length > 0 ? (
                                            <ProductList products={loadProductsForCategory(selectedCategory.id)}/>
                                        ) : (
                                            <Typography>Нет товаров в этой категории</Typography>
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mb: 4
                                        }}
                                    >
                                        <Typography variant="h4">
                                            Подкатегориии {selectedCategory.name}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            startIcon={<AddIcon/>}
                                            sx={{
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                backdropFilter: 'blur(5px)',
                                                padding: '10px 20px',
                                                fontSize: '16px',
                                                transition: 'all 0.3s ease',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                                    transform: 'translateY(-2px)',
                                                },
                                            }}
                                            onClick={() => handleOpenAddModal(selectedCategory.id)}
                                        >
                                            Добавить подкатегорию
                                        </Button>
                                    </Box>
                                    {selectedCategory.children!.map((subCategory) => {
                                        const products = loadProductsForCategory(subCategory.id);
                                        return (
                                            <Box key={subCategory.id} mb={4}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        mb: 2
                                                    }}
                                                >
                                                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                                                        <Typography variant="h4">
                                                            <HoverLink
                                                                to={`/catalog/${subCategory.id}`}
                                                                label={subCategory.name}
                                                                color="inherit"
                                                                underlineColor="black"
                                                                variant="h4"
                                                            />
                                                        </Typography>
                                                        <Box sx={{display: 'flex', gap: 0.5}}>
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    setCategoryToEdit(subCategory);
                                                                    setIsEditModalOpen(true);
                                                                }}
                                                                sx={{
                                                                    color: 'black',
                                                                    '&:hover': {
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.1)'
                                                                    }
                                                                }}
                                                                size="small"
                                                            >
                                                                <EditIcon fontSize="small"/>
                                                            </IconButton>
                                                            <Tooltip
                                                                title={!canDeleteCategory(subCategory) ? "Нельзя удалить категорию с подкатегориями или товарами" : ""}>
                                                                <span>
                                                                    <IconButton
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            handleDeleteClick(subCategory);
                                                                        }}
                                                                        disabled={!canDeleteCategory(subCategory)}
                                                                        sx={{
                                                                            color: 'red',
                                                                            '&:hover': {
                                                                                backgroundColor: canDeleteCategory(subCategory) ? 'rgba(255, 0, 0, 0.1)' : 'none'
                                                                            },
                                                                            '&.Mui-disabled': {
                                                                                color: 'rgba(128, 128, 128, 0.5)'
                                                                            }
                                                                        }}
                                                                        size="small"
                                                                    >
                                                                        <DeleteIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                    {!isFinalCategory(subCategory) && (
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<AddIcon/>}
                                                            size="small"
                                                            sx={{
                                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                                backdropFilter: 'blur(5px)',
                                                                padding: '8px 16px',
                                                                fontSize: '14px',
                                                                transition: 'all 0.3s ease',
                                                                '&:hover': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                                                    transform: 'translateY(-2px)',
                                                                },
                                                            }}
                                                            onClick={() => handleOpenAddModal(subCategory.id)}
                                                        >
                                                            Добавить подкатегорию
                                                        </Button>
                                                    )}
                                                    {isFinalCategory(subCategory) && (
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<AddIcon/>}
                                                            size="small"
                                                            sx={{
                                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                                backdropFilter: 'blur(5px)',
                                                                padding: '8px 16px',
                                                                fontSize: '14px',
                                                                transition: 'all 0.3s ease',
                                                                '&:hover': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                                                    transform: 'translateY(-2px)',
                                                                },
                                                            }}
                                                            onClick={() => handleAddProduct(subCategory.id)}
                                                        >
                                                            Добавить продукт
                                                        </Button>
                                                    )}
                                                </Box>
                                                {isFinalCategory(subCategory) ? (
                                                    <Box>
                                                        {productsLoading(subCategory.id) ? (
                                                            <CircularProgress/>
                                                        ) : products.length > 0 ? (
                                                            <ProductList products={products} horizontal/>
                                                        ) : (
                                                            <Typography>Нет товаров в этой категории</Typography>
                                                        )}
                                                    </Box>
                                                ) : (
                                                    <CategoryList
                                                        categories={subCategory.children!}
                                                        horizontal
                                                        onEditClick={(category) => {
                                                            setCategoryToEdit(category);
                                                            setIsEditModalOpen(true);
                                                        }}
                                                        onDeleteClick={handleDeleteClick}
                                                    />
                                                )}
                                            </Box>
                                        );
                                    })}
                                </>
                            )
                        ) : (
                            <CategoryList
                                categories={categories}
                                onEditClick={(category) => {
                                    setCategoryToEdit(category);
                                    setIsEditModalOpen(true);
                                }}
                                onDeleteClick={handleDeleteClick}
                            />
                        )}
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default CatalogPage;
