import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box, Grid, Divider, IconButton
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IProduct } from '../../store/slices/productSlice';
import HoverLink from '../HoverLink';
import { deleteProduct } from '../../store/slices/productSlice';
import { useAppDispatch } from '../../store/hooks';

interface ProductCardProps {
  product: IProduct;
}

const ProductCardMini: React.FC<ProductCardProps> = ({ product }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const productImage = product.image ? product.image : '/images/placeholder.jpg';

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = '/images/placeholder.jpg';
  };

  const handleDelete = async () => {
    if (window.confirm('Вы уверены, что хотите удалить этот продукт?')) {
      try {
        await dispatch(deleteProduct(product.id)).unwrap();
        navigate(`/catalog/${product.categoryId}`);
      } catch (error) {
        console.error('Failed to delete product:', error);
      }
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        boxShadow: 3,
        transition: 'box-shadow 0.3s ease',
        borderRadius: '16px',
        '&:hover': {
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', paddingTop: '100%' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${productImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(10px)',
            transform: 'scale(1.1)',
          }}
        />
        <CardMedia
          component="img"
          image={productImage}
          alt={product.name}
          onError={handleImageError}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            maxHeight: '100%',
            maxWidth: '100%',
            transform: 'translate(-50%, -50%)',
            objectFit: 'contain',
          }}
        />
        <Box sx={{ 
          position: 'absolute', 
          top: 8, 
          right: 8, 
          zIndex: 1,
          display: 'flex',
          gap: 1,
          backgroundColor: 'rgba(255,255,255,0.8)',
          borderRadius: '8px',
          padding: '4px'
        }}>
          <IconButton 
            component={Link} 
            to={`/products/${product.id}/edit`}
            size="small"
            sx={{ 
              backgroundColor: 'white',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.1)' }
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton 
            size="small"
            sx={{ 
              backgroundColor: 'white',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.1)' }
            }}
            onClick={handleDelete}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      <CardContent sx={{ flexGrow: 1 }}>
        <HoverLink
          to={`/products/${product.id}`}
          label={product.name}
          color="inherit"
          variant="h5"
          sx={{
            my: 1
          }}
        />

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
          }}
        >
          {product.description}
        </Typography>

        <Divider sx={{ m: 0, mt: 1 }} />

        {product.card_specs && (
          <Box my={0.5}>
            <Grid container>
              {Object.entries(product.card_specs)
                .slice(0, 3)
                .map(([key, value], index) => (
                  <React.Fragment key={key}>
                    <Grid item xs={8} sx={{ padding: 0, my: 1, pr: 1 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {key}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ padding: 0, my: 1 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ textAlign: 'left', wordBreak: 'break-word' }}
                      >
                        {value}
                      </Typography>
                    </Grid>

                    {index < Object.entries(product.card_specs).length - 1 && (
                      <Grid item xs={12} sx={{ padding: 0 }}>
                        <Divider sx={{ m: 0 }} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
            </Grid>
          </Box>
        )}
      </CardContent>

      <Box sx={{ padding: 2 }}>
        <Button
          variant="contained"
          fullWidth
          component={Link}
          to={`/products/${product.id}`}
          sx={{
            backgroundColor: 'rgba(240,240,240)',
            backdropFilter: 'blur(8px)',
            color: 'black',
            '&:hover': {
              backgroundColor: 'rgba(200, 200, 200)',
            },
          }}
        >
          Подробнее
        </Button>
      </Box>
    </Card>
  );
};

export default ProductCardMini;
