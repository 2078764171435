import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Container,
  Grid,
  CircularProgress,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { Category, fetchCategories, deleteCategory } from '../store/slices/catalogSlice';
import CategoryCard from '../components/CategoryCard';
import AnimatedCard from '../components/AnimatedCard';
import { useNavbarStyle } from '../components/NavbarStyleContext';
import EditCategoryModal from '../components/Modals/EditCategoryModal';
import DeleteCategoryModal from '../components/Modals/DeleteCategoryModal';
import AddCategoryModal from "../components/Modals/AddCategoryModal";

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { categories, loading, error } = useAppSelector((state) => state.catalog);
  const { setNavbarStyle } = useNavbarStyle();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState<Category | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<Category | null>(null);
  const [selectedParentId, setSelectedParentId] = useState<string | undefined>(undefined);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  console.log(categories)

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    setNavbarStyle('light');

    return () => {
      setNavbarStyle('dark');
    };
  }, [setNavbarStyle]);

  const handleEditClick = (category: Category) => {
    setCategoryToEdit(category);
    setIsEditModalOpen(true);
  };

  const handleDeleteClick = (category: Category) => {
    setCategoryToDelete(category);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (categoryToDelete) {
      try {
        await dispatch(deleteCategory(categoryToDelete.id)).unwrap();
        setIsDeleteModalOpen(false);
        setCategoryToDelete(null);
      } catch (error) {
        console.error('Failed to delete category:', error);
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error" variant="h6">
          Ошибка: {error}
        </Typography>
      </Container>
    );
  }

  const handleOpenAddModal = (parentId?: string) => {
    setSelectedParentId(parentId);
    setIsAddModalOpen(true);
  };

  return (
    <>
      <AddCategoryModal
          open={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          parentCategoryId={selectedParentId}
      />
      {categoryToEdit && (
        <EditCategoryModal
          open={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setCategoryToEdit(null);
          }}
          category={categoryToEdit}
        />
      )}
      {categoryToDelete && (
        <DeleteCategoryModal
          open={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
            setCategoryToDelete(null);
          }}
          onConfirm={handleConfirmDelete}
          category={categoryToDelete}
        />
      )}
      <Container maxWidth="xl">
        <Box py={4}>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 4 
            }}
          >
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{
                fontWeight: 700,
              }}
            >
              Управление каталогом
            </Typography>
            <Button
                variant="contained"
                startIcon={<AddIcon/>}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  backdropFilter: 'blur(5px)',
                  padding: '10px 20px',
                  fontSize: '16px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    transform: 'translateY(-2px)',
                  },
                }}
                onClick={() => handleOpenAddModal()}
            >
              Добавить категорию
            </Button>
          </Box>
          
          <Grid container spacing={3}>
            {categories
              .filter((category) => category.type === 'root')
              .map((category, idx) => (
                <Grid item key={category.id} xs={12} sm={6} md={4}>
                  <AnimatedCard
                    initialDelay={200}
                    stepDelay={50}
                    animationDuration={1000}
                  >
                    <CategoryCard 
                      category={category} 
                      onEditClick={handleEditClick}
                      onDeleteClick={handleDeleteClick}
                    />
                  </AnimatedCard>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default HomePage; 